import React from 'react';
import { useStyletron } from 'baseui';
import { Helmet } from 'react-helmet';
import { Select } from 'baseui/select';
import Header from '../controllers/header';

const Home = ({
    user, onLogin, onLogout, currentPeriod, onEmailChanged, onCheck, emailToApprove, approvalStatus, approved,
}) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <>
            <Helmet>
                <title>Mask Pickup Tracker</title>
            </Helmet>

            <Header />

            <div className={css({ display: 'flex', justifyContent: 'center' })}>
                {(user === null) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>You are not currently logged in. You must log in as a valid and authorized user to proceed.</p>
                        <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogin}>Login</button>
                    </div>
                )}
                {(user && user.authorized !== true) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>You are not authorized to use this application.</p>
                        <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogout}>Logout</button>
                    </div>
                )}
                {(user && user.authorized === true && !currentPeriod) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>The current time does not fall within an available pickup period.</p>
                    </div>
                )}
                {(user && user.authorized === true && currentPeriod) && (
                    <div className={css({
                        marginLeft: '10%', marginRight: '10%', marginTop: '60px', textAlign: 'left',
                    })}
                    >
                        <h2 className={css({ textTransform: 'uppercase', fontWeight: 'normal' })}>PERIOD: {currentPeriod.weekDay} {currentPeriod.start} to {currentPeriod.end}</h2>
                        <h1>Is Team Leader approved to pickup masks?</h1>
                        <h2>1. Ask for identification</h2>
                        <h2>2. Enter RRH email username</h2>
                        <div className={css({ lineHeight: '36px', display: 'flex', flexWrap: 'wrap' })}>
                            <Select
                                className={css({ width: '200px' })}
                                options={approved}
                                labelKey="label"
                                valueKey="value"
                                onChange={({ value }) => onEmailChanged(value)}
                                value={emailToApprove}
                                overrides={{
                                    Root: {
                                        style: () => ({ width: '200px' }),
                                        display: 'inline-block',
                                        flex: '1',
                                        marginTop: '10px',
                                    },
                                    OptionContent: {
                                        style: () => ({ textTransform: 'capitalize' }),
                                    },
                                    Dropdown: {
                                        style: () => ({
                                            height: '200px',
                                        }),
                                    },
                                }}
                            />
                            <div className={css({ flex: 1, whiteSpace: 'nowrap' })}>
                                @rochesterregional.org
                                <button
                                    onClick={() => onCheck(emailToApprove, currentPeriod)}
                                    className={css({
                                        marginTop: '10px;', border: 'solid 1px #0077c8', padding: '5px', fontSize: '18px', marginLeft: '15px', color: '#0077c8',
                                    })}
                                >Check
                                </button>
                            </div>
                        </div>

                        { approvalStatus === 'OK' && (
                            <div className={css({
                                border: 'solid 4px #00FF00', textAlign: 'center', marginTop: '10px', marginBottom: '15px',
                            })}
                            >
                                <h2>Approved</h2>
                                <h3>Team Leader may pick up masks.</h3>
                            </div>)}
                        { approvalStatus === 'NO_AUTH' && (
                            <div className={css({
                                border: 'solid 4px #FF0000', textAlign: 'center', marginTop: '10px', marginBottom: '15px',
                            })}
                            >
                                <h2>Denied</h2>
                                <h3>User not on approved Team Leader list.</h3>
                            </div>)}
                        { approvalStatus === 'COUNT_EXCEEDED' && (
                            <div className={css({
                                border: 'solid 4px #FF0000', textAlign: 'center', marginTop: '10px', marginBottom: '15px',
                            })}
                            >
                                <h2>Denied</h2>
                                <h3>Masks already picked up for this period.</h3>
                            </div>)}
                    </div>
                )}
            </div>
        </>
    );
};

export default Home;
