import { takeLatest, put, call, take, select } from 'redux-saga/effects';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import { SAGA_ADD_APPROVED, SAGA_WATCH_APPROVED, SAGA_DELETE_APPROVED, SAGA_CHECK_APPROVED, setApproved, removeApproved } from './approved-actions';
import { createWatcher } from '../libs/utils';
import { setUIState } from './ui-actions';
import { fetchSingleRef, fetchRef } from '../libs/utils';

function* workerAddApproved() {
    // GET USER

    const userId = yield select(state => state.user.email);

    // GET EMAIL

    const email = yield select(state => state.ui.approvedEmail);

    if (!email || email.length === 0) {
        return;
    }

    const fullEmail = `${email}@rochesterregional.org`;
    const matchEmail = fullEmail.toLowerCase();

    // STORE

    const db = firebase.firestore();
    const approvedRef = db.collection('approved');
    yield approvedRef.doc(fullEmail).set({
        email: fullEmail, matchEmail, approved: true, user: userId, created: firebase.firestore.FieldValue.serverTimestamp(),
    });

    // CLEAR

    yield put(setUIState('approvedEmail', null));
}

export function* addApproved() {
    yield takeLatest(SAGA_ADD_APPROVED, workerAddApproved);
}

function* workerDeleteApproved(action) {
    // CONFIRM

    if (confirm(`Remove ${action.approved.email}?`) === false) {    // eslint-disable-line
        return;
    }

    // DELETE

    const db = firebase.firestore();
    const approvedRef = db.collection('approved');
    yield approvedRef.doc(action.approved._id).delete();
}

export function* deleteApproved() {
    yield takeLatest(SAGA_DELETE_APPROVED, workerDeleteApproved);
}

let approvedChannel;
function* workerWatchApproved() {
    // WATCH TOPICS

    const approvedWatcher = createWatcher('approved');

    // CLOSE EXISTING CHANNEL

    if (approvedChannel) {
        approvedChannel.close();
    }

    approvedChannel = yield call(approvedWatcher);

    while (true) {
        // RESPOND TO EMITTER\

        const approved = yield take(approvedChannel);

        if (!approved.removed) {
            yield put(setApproved(approved));
        } else {
            yield put(removeApproved(approved));
        }
    }
}

export function* watchApproved() {
    yield takeLatest(SAGA_WATCH_APPROVED, workerWatchApproved);
}

function* workerCheck(action) {
    // GET USER

    const user = yield select(state => state.user);

    // RESET APPROVAL STATUS

    yield put(setUIState('approvalStatus', ''));

    // CHECK IF APPROVED TO PICKUP

    const fullMatchEmail = `${action.email}@rochesterregional.org`.toLowerCase();
    const db = firebase.firestore();
    const approvedRef = db.collection('approved').where('matchEmail', '==', fullMatchEmail);
    const approvedAccount = yield fetchSingleRef(approvedRef);

    if (!approvedAccount) {
        yield put(setUIState('approvalStatus', 'NO_AUTH'));
        return;
    }

    // CHECK IF ALREADY PICKED UP

    const now = moment();
    const date = now.format('MM-DD');
    const pickupsRef = db.collection('pickups').where('account', '==', approvedAccount._id).where('period', '==', action.period._id).where('date', '==', date);
    const pickups = yield fetchRef(pickupsRef);

    if (pickups.length > 0) {
        yield put(setUIState('approvalStatus', 'COUNT_EXCEEDED'));
        return;
    }

    // LOG PICKUP

    const pickupId = uuidv4();
    const pickup = {
        account: approvedAccount._id,
        period: action.period._id,
        accountDetails: approvedAccount,
        periodDetails: action.period,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        date,
        userHandle: user.handle,
        userEmail: user.email,
    };
    db.collection('pickups').doc(pickupId).set(pickup);

    // SET OK APPROVAL STATUS

    yield put(setUIState('approvalStatus', 'OK'));
}

export function* check() {
    yield takeLatest(SAGA_CHECK_APPROVED, workerCheck);
}
