import { connect } from 'react-redux';
import firebase from 'firebase/app';
import moment from 'moment';
import Home from '../components/home';
import { showLogin } from '../state/user-actions';
import { setUIState } from '../state/ui-actions';
import { check } from '../state/approved-actions';

const mapStateToProps = (state) => {
    // GET CURRENT PERIOD

    const { periods } = state;
    const now = moment();
    const dayIndex = now.format('e') - 1;
    const hour = now.format('HH').padStart(2, '0');
    const min = now.format('mm').padStart(2, '0');
    const time = hour + min;
    const currentPeriod = periods.find(p => p.weekDayIndex === dayIndex && p.start <= time && p.end >= time);
    const approved = state.approvedAccounts.map(a => ({ value: a.email.split('@')[0], label: a.email.split('@')[0] }));

    return {
        user: state.user ? state.user : null,
        currentPeriod,
        emailToApprove: typeof (state.ui.emailToApprove) !== 'undefined' ? state.ui.emailToApprove : '',
        approvalStatus: state.ui.approvalStatus,
        approved,
    };
};

const mapDispatchToProps = dispatch => ({
    onLogin: () => {
        dispatch(showLogin());
    },
    onLogout: () => {
        firebase.auth().signOut();
    },
    onEmailChanged: (email) => {
        dispatch(setUIState('emailToApprove', email));
    },
    onCheck: (email, period) => {
        if (email.length === 0) {
            return;
        }
        dispatch(check(email[0].value, period));
    },
});

const HomeController = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomeController;
