import { takeLatest, put, call, take, select } from 'redux-saga/effects';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { fetchSingleRef } from '../libs/utils';
import createAuthWatcher from '../libs/auth-watcher';
import { SAGA_INIT_APP, SAGA_SAVE_USER, SAGA_SHOW_LOGIN, setUserInfo, saveUser as actionSaveUser, showLogin as actionShowLogin, unregisterLoginAction } from './user-actions';
import { setUIState } from './ui-actions';
import { watchApproved } from './approved-actions';
import { watchPeriods } from './settings-actions';

let authChannel;
function* workerInitApp() {
    // Create a watcher to listen for user activity

    const watcher = createAuthWatcher();

    // If the channel is alredy established, close it.

    if (authChannel) {
        authChannel.close();
    }

    // Create the channel that uses the watcher. The channel is watched forever.

    authChannel = yield call(watcher);

    while (true) {
        // This blocks until an event arrives.

        const userActivity = yield take(authChannel);
        console.log('USER ACTIVITY:', userActivity);

        // If we don't have a user, we are not logged in (and that's ok)

        if (!userActivity || !userActivity.user) {
            yield put(setUserInfo(null));
            continue;
        }

        const { user } = userActivity;

        const {
            providerData, email, emailVerified, uid, displayName,
        } = user;
        const [firstProvider] = providerData;
        const { photoURL } = firstProvider;


        const userInfo = {
            handle: displayName,
            email,
            emailVerified,
            photoURL,
            _id: uid,
            providerData,
        };

        // STORE USER

        yield put(actionSaveUser(Object.assign(userInfo)));

        // HIDE LOGIN

        yield put(setUIState('showLogin', false));

        // WATCH APPROVED

        yield put(watchApproved());

        // WATCH PERIODS

        yield put(watchPeriods());

        // HANDLE REGISTERED INIT ACTIONS

        const actions = yield select(state => state.loginActions);
        for (let idx = 0; idx < actions.length; idx++) {
            yield put(actions[idx]);
            yield put(unregisterLoginAction(actions[idx]));
        }
    }
}

export function* initApp() {
    yield takeLatest(SAGA_INIT_APP, workerInitApp);
}

function* workerSaveUser(action) {
    const user = {
        email: action.userInfo.email,
        handle: action.userInfo.handle,
        photoURL: action.userInfo.photoURL,
    };
    const userId = action.userInfo._id;

    // DO WE ALREADY HAVE THIS USER?

    const db = firebase.firestore();
    const userRef = db.collection('users');
    const userQuery = userRef.where('email', '==', user.email);

    const existingUser = yield fetchSingleRef(userQuery);

    // IF NEW USER, SET HANDLE

    if (!existingUser) {
        user.authId = action.userInfo._id;
        user.authorized = false;
    } else {
        user.handle = existingUser.handle ? existingUser.handle : action.userInfo.handle;
        user.altEmail = existingUser.altEmail ? existingUser.altEmail : null;
        user.name = existingUser.name ? existingUser.name : action.userInfo.handle;
        user.authorized = existingUser.authorized ? existingUser.authorized : false;
    }

    // SAVE USER

    yield put(setUserInfo(user));

    // STORE USER
    yield userRef.doc(userId).set(user);
}

export function* saveUser() {
    yield takeLatest(SAGA_SAVE_USER, workerSaveUser);
}

// WATCH FOR PATH CHANGES AND DISPATCH APPROPRIATE INIT SAGAS

function* workerWatchLocation(action) { // eslint-disable-line
    const path = action.payload.location.pathname;
    const host = window.location.hostname;  // eslint-disable-line
    const qs = action.payload.location.search;

    console.log('HOST:', host);
    console.log('PATH:', path);
    console.log('QUERY:', qs);

    const parts = path.split('/');
    const section = parts[1];
    console.log('SECTION:', section);
    let param = null;

    if (section.length > 1) {
        param = parts[2];   // eslint-disable-line
        console.log('PARAM:', param);
    }

    // CLEAR DOWNLOAD FLAGS

    yield put(setUIState('csvDownloadReady', false));

    // SWITCH ON SECTION

    switch (section) {
    case (''): {
        break;
    }
    default:
    }

    // AUTH/UI PARAMS?

    if (qs && qs.indexOf('mode') >= 0 && qs.indexOf('select') >= 0) {
        yield put(actionShowLogin());
    }
}

export function* watchLocation() {
    yield takeLatest('@@router/LOCATION_CHANGE', workerWatchLocation);
}

// SHOW LOGIN

let ui;
function* workerShowLogin(action) { // eslint-disable-line
    yield put(setUIState('showLogin', true));

    // FirebaseUI config.

    const uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: () => {
                // alert('Sign In Successfult:', result);
            },
        },
        signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        ],
        signInFlow: 'popup',
        // Terms of service url.
        tosUrl: '<your-tos-url>',
    };

    // The start method will wait until the DOM is loaded.

    setTimeout(() => {
        if (!ui) {
            ui = new firebaseui.auth.AuthUI(firebase.auth());
        }
        ui.start('#fbauth', uiConfig);
    }, 500);
}

export function* showLogin() {
    yield takeLatest(SAGA_SHOW_LOGIN, workerShowLogin);
}
