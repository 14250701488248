import { all } from 'redux-saga/effects';
import { initApp, saveUser, watchLocation, showLogin } from './user-sagas';
import { addApproved, watchApproved, deleteApproved, check } from './approved-sagas';
import { addPeriod, watchPeriods, deletePeriod, savePeriod, fetchPickups } from './settings-sagas';

// single entry point to start all Sagas at once

export default function* rootSaga() {
    yield all([
        // USER SAGAS

        initApp(),
        saveUser(),
        watchLocation(),
        showLogin(),

        // APPROVED

        addApproved(),
        watchApproved(),
        deleteApproved(),
        check(),

        // SETTINGS

        addPeriod(),
        watchPeriods(),
        deletePeriod(),
        savePeriod(),
        fetchPickups(),

    ]);
}
