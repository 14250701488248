import React from 'react';
import { useStyletron } from 'baseui';
import Select from 'react-select';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import { Helmet } from 'react-helmet';
import Header from '../controllers/header';

const weekDayOptions = [
    { value: 0, label: 'Monday' },
    { value: 1, label: 'Tuesday' },
    { value: 2, label: 'Wednesday' },
    { value: 3, label: 'Thursday' },
    { value: 4, label: 'Friday' },
    { value: 5, label: 'Saturday' },
    { value: 6, label: 'Sunday' },
];

const hourOptions = [];
for (let h = 0; h < 24; h += 1) {
    for (let m = 0; m <= 45; m += 15) {
        hourOptions.push({ value: `${h.toString().padStart(2, '0')}${m.toString().padStart(2, '0')}`, label: `${h.toString().padStart(2, '0')}${m.toString().padStart(2, '0')}` });
    }
}
hourOptions.push({ value: '2400', label: '2400' });

const Settings = ({
    user, onLogin, onLogout, onAddNewPeriod, periods, onDeletePeriod, onWeekDayChanged, onStartChanged, onEndChanged, savingPeriod, onDownloadPickups, downloadReady, pickups, onDownloaded,
}) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <>
            <Helmet>
                <title>Mask Pickup Tracker - Settings</title>
            </Helmet>

            <Header />

            <div className={css({ display: 'flex', justifyContent: 'center' })}>
                {(user === null) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>You are not currently logged in. You must log in as a valid and authorized user to proceed.</p>
                        <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogin}>Login</button>
                    </div>
                )}
                {(user && user.authorized !== true) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>You are not authorized to use this application.</p>
                        <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogout}>Logout</button>
                    </div>
                )}
                {(user && user.authorized === true) && (
                    <div className={css({
                        marginLeft: '10%', marginRight: '10%', marginTop: '60px', textAlign: 'left',
                    })}
                    >
                        <h2>Periods <button onClick={onAddNewPeriod} className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })}>Add New</button></h2>

                        { periods.length > 0 && <h3 className={css({ textDecoration: 'underline' })}>Active Pickup Periods</h3> }
                        { _.sortBy(periods, ['weekDayIndex', 'start']).map(period => (
                            <div
                                key={period._id}
                                className={css({
                                    display: 'flex', flexWrap: 'wrap', marginBottom: '10px', padding: '3px', backgroundColor: period._id === savingPeriod ? '#00FF00' : '#FFFFFF',
                                })}
                            >
                                <span className={css({ marginRight: '15px' })}>
                                    <Select
                                        value={weekDayOptions.find(wdo => wdo.value === period.weekDayIndex)}
                                        className={css({ width: '130px' })}
                                        label="Weekday"
                                        options={weekDayOptions}
                                        onChange={wd => onWeekDayChanged(period, wd)}
                                    />
                                </span>

                                <span className={css({ marginRight: '15px' })}>
                                    <Select
                                        value={hourOptions.find(hr => hr.value === period.start)}
                                        className={css({ width: '100px' })}
                                        label="Start"
                                        options={hourOptions}
                                        onChange={hr => onStartChanged(period, hr)}
                                    />
                                </span>

                                <span className={css({ marginRight: '15px', position: 'relative', top: '6px' })}>To</span>

                                <span className={css({ marginRight: '15px' })}>
                                    <Select
                                        value={hourOptions.find(hr => hr.value === period.end)}
                                        className={css({ width: '100px' })}
                                        label="End"
                                        options={hourOptions}
                                        onChange={hr => onEndChanged(period, hr)}
                                    />
                                </span>
                                <button
                                    onClick={() => onDeletePeriod(period)}
                                    className={css({
                                        display: 'inline-block', marginLeft: '10px', border: 'none', cursor: 'pointer', padding: '1px', color: '#FF0000', backgroundColor: '#FFFFFF',
                                    })}
                                >Delete
                                </button>
                            </div>

                        )) }

                        <button
                            className={css({
                                curser: 'pointer', border: 'solid 1px #0077c8', color: '#0066c8', padding: '3px',
                            })}
                            onClick={onDownloadPickups}
                        >Prepare Pickup Log as CSV
                        </button>

                        { downloadReady && (
                            <div className={css({ marginTop: '10px' })}>
                                <CSVLink
                                    className={css({
                                        fontWeight: 'bold', color: '#013220', cursor: 'pointer', lineHeight: '12px', border: 'solid 1px #013220', textDecoration: 'none', padding: '3px', fontSize: '12px',
                                    })}
                                    data={pickups}
                                    filename="pickup-log.csv"
                                    onClick={onDownloaded}
                                >CSV File Ready... Download
                                </CSVLink>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default Settings;
