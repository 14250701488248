export const SAGA_ADD_PERIOD = 'SAGA_ADD_PERIOD';
export const SAGA_DELETE_PERIOD = 'SAGA_DELETE_PERIOD';
export const SAGA_WATCH_PERIODS = 'SAGA_WATCH_PERIODS';
export const SET_PERIOD = 'SET_PERIOD';
export const REMOVE_PERIOD = 'REMOVE_PERIOD';
export const SAGA_SAVE_PERIOD = 'SAGA_SAVE_PERIOD';
export const SAGA_FETCH_PICKUPS = 'SAGA_FETCH_PICKUPS';
export const SET_PICKUPS = 'SET_PICKUPS';

// Store user info into local state.

export function addPeriod() {
    return {
        type: SAGA_ADD_PERIOD,
    };
}

export function deletePeriod(period) {
    return {
        type: SAGA_DELETE_PERIOD,
        period,
    };
}

export function watchPeriods() {
    return {
        type: SAGA_WATCH_PERIODS,
    };
}

export function setPeriod(period) {
    return {
        type: SET_PERIOD,
        period,
    };
}

export function removePeriod(period) {
    return {
        type: REMOVE_PERIOD,
        period,
    };
}

export function savePeriod(period) {
    return {
        type: SAGA_SAVE_PERIOD,
        period,
    };
}

export function fetchPickups() {
    return {
        type: SAGA_FETCH_PICKUPS,
    };
}

export function setPickups(pickups) {
    return {
        type: SET_PICKUPS,
        pickups,
    };
}
