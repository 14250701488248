import React from 'react';
import { useStyletron } from 'baseui';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import Header from '../controllers/header';

const ApprovedLeaders = ({
    user, onLogin, onLogout, approvedEmail, onEmailChanged, onAddNew, approvedAccounts, onDelete,
}) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <>
            <Helmet>
                <title>Mask Pickup Tracker - Approved Leaders</title>
            </Helmet>

            <Header />

            <div className={css({ display: 'flex', justifyContent: 'center' })}>
                {(user === null) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>You are not currently logged in. You must log in as a valid and authorized user to proceed.</p>
                        <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogin}>Login</button>
                    </div>
                )}
                {(user && user.authorized !== true) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>You are not authorized to use this application.</p>
                        <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogout}>Logout</button>
                    </div>
                )}
                {(user && user.authorized === true) && (
                    <div className={css({ margin: '10%', textAlign: 'left' })}>
                        <h2>Approved Leaders</h2>
                        <h3 className={css({ textDecoration: 'underline' })}>Add New Approved Leader</h3>
                        Username: <input value={approvedEmail} onChange={e => onEmailChanged(e.target.value)} className={css({ fontSize: '18px', padding: '2px' })} type="text" /> @rochesterregional.org <button onClick={onAddNew} className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })}>Add New</button>

                        { approvedAccounts.length > 0 && <h3 className={css({ textDecoration: 'underline' })}>List of Approved Accounts</h3> }
                        { _.sortBy(approvedAccounts, ['email']).map(account => (
                            <div key={account._id} className={css({ whiteSpace: 'nowrap', marginBottom: '5px' })}>
                                <span className={css({
                                    display: 'inline-block', width: '200px', fontSize: '12px', '@media screen and (min-width: 564px)': { width: '300px', fontSize: '16px', paddingTop: '5px;' },
                                })}
                                >{account.email}
                                </span>
                                <button
                                    onClick={() => onDelete(account)}
                                    className={css({
                                        display: 'inline-block', marginLeft: '10px', border: 'none', cursor: 'pointer', padding: '1px', color: '#FF0000',
                                    })}
                                >Delete
                                </button>
                            </div>
                        )) }
                    </div>
                )}
            </div>
        </>
    );
};

export default ApprovedLeaders;
