import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    SIZE,
    ROLE,
} from 'baseui/modal';
import './firebaseui.css';

const LoginRegister = ({
    showLogin, error, onCancel,
}) => (
    <Modal
        onClose={onCancel}
        closeable
        isOpen={showLogin}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
    >
        <ModalHeader>Mask Tracker Login</ModalHeader>
        <ModalBody>
            <div id="fbauth" />
            {error}
        </ModalBody>
    </Modal>

);

export default LoginRegister;
