export const SAGA_INIT_APP = 'SAGA_INIT_APP';
export const SAGA_SAVE_USER = 'SAGA_SAVE_USER';
export const SET_USER_INFO = 'SET_USER_INFO';
export const UPDATE_USER_CHARACTER = 'UPDATE_USER_CHARACTER';
export const REGISTER_INIT_ACTION = 'REGISTER_INIT_ACTION';
export const UNREGISTER_INIT_ACTION = 'UNREGISTER_INIT_ACTION';
export const SAGA_SHOW_LOGIN = 'SAGA_SHOW_LOGIN';
export const REGISTER_LOGIN_ACTION = 'REGISTER_LOGIN_ACTION';
export const UNREGISTER_LOGIN_ACTION = 'UNREGISTER_LOGIN_ACTION';
export const SAGA_WATCH_USER_NOUNS = 'SAGA_WATCH_USER_NOUNS';
export const SET_USER_NOUN = 'SET_USER_NOUN';
export const REMOVE_USER_NOUN = 'REMOVE_USER_NOUN';


export function initApp() {
    return {
        type: SAGA_INIT_APP,
    };
}

export function setUserInfo(userInfo) {
    return {
        type: SET_USER_INFO,
        userInfo,
    };
}

export function saveUser(userInfo) {
    return {
        type: SAGA_SAVE_USER,
        userInfo,
    };
}

export function updateUserCharacter(world) {
    return {
        type: UPDATE_USER_CHARACTER,
        world,
    };
}

export function registerInitAction(action) {
    return {
        type: REGISTER_INIT_ACTION,
        action,
    };
}

export function unregisterInitAction(action) {
    return {
        type: UNREGISTER_INIT_ACTION,
        action,
    };
}

export function registerLoginAction(action) {
    return {
        type: REGISTER_LOGIN_ACTION,
        action,
    };
}

export function unregisterLoginAction(action) {
    return {
        type: UNREGISTER_LOGIN_ACTION,
        action,
    };
}

export function showLogin() {
    return {
        type: SAGA_SHOW_LOGIN,
    };
}

export function watchUserNouns(user) {
    return {
        type: SAGA_WATCH_USER_NOUNS,
        user,
    };
}

export function setUserNoun(noun) {
    return {
        type: SET_USER_NOUN,
        noun,
    };
}

export function removeUserNoun(noun) {
    return {
        type: REMOVE_USER_NOUN,
        noun,
    };
}
