import { connect } from 'react-redux';
import firebase from 'firebase/app';
import Header from '../components/header';
import { showLogin } from '../state/user-actions';

const mapStateToProps = state => ({
    user: state.user ? state.user : null,
});

const mapDispatchToProps = dispatch => ({
    onShowLogin: () => {
        dispatch(showLogin());
    },
    onLogout: () => {
        firebase.auth().signOut();
    },
});

const HeaderController = connect(mapStateToProps, mapDispatchToProps)(Header);

export default HeaderController;
