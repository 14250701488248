export const SAGA_ADD_APPROVED = 'SAGA_ADD_APPROVED';
export const SAGA_DELETE_APPROVED = 'SAGA_DELETE_APPROVED';
export const SAGA_WATCH_APPROVED = 'SAGA_WATCH_APPROVED';
export const SET_APPROVED = 'SET_APPROVED';
export const REMOVE_APPROVED = 'REMOVE_APPROVED';
export const SAGA_CHECK_APPROVED = 'SAGA_CHECK_APPROVED';

// Store user info into local state.

export function addApproved() {
    return {
        type: SAGA_ADD_APPROVED,
    };
}

export function deleteApproved(approved) {
    return {
        type: SAGA_DELETE_APPROVED,
        approved,
    };
}

export function watchApproved() {
    return {
        type: SAGA_WATCH_APPROVED,
    };
}

export function setApproved(approved) {
    return {
        type: SET_APPROVED,
        approved,
    };
}

export function removeApproved(approved) {
    return {
        type: REMOVE_APPROVED,
        approved,
    };
}

export function check(email, period) {
    return {
        type: SAGA_CHECK_APPROVED,
        email,
        period,
    };
}
