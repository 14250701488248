import { connect } from 'react-redux';
import LoginRegister from '../components/login-register';
import { setUIState } from '../state/ui-actions';

const mapStateToProps = state => ({
    user: state.user ? state.user : {},
    showLogin: state.ui.showLogin,
});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(setUIState('showLogin', false));
    },
});

const LoginRegisterController = connect(mapStateToProps, mapDispatchToProps)(LoginRegister);

export default LoginRegisterController;
