import firebase from 'firebase/app';
import { eventChannel } from 'redux-saga';

export function fetchRef(ref) {
    return new Promise((resolve) => {
        ref.get().then((snapshot) => {
            const items = [];
            snapshot.forEach((doc) => {
                const item = doc.data();
                item._id = doc.id;
                items.push(item);
            });
            resolve(items);
        });
    });
}

// FETCH A SINGLE ITEM

export function fetchSingleRef(ref) {
    return new Promise((resolve) => {
        ref.get().then((_doc) => {
            let doc = _doc;
            if (doc.docs && doc.docs.length) {
                [doc] = doc.docs;
            }
            if (!doc.exists) {
                resolve(null);
                return;
            }
            const item = doc.data();
            item._id = doc.id;

            resolve(item);
        }, (error) => {
            console.warn(error);
            resolve(null);
        });
    });
}

export function createWatcher(collection, keyField, keyValue, op = '==', orderBy = null, limit = null) {
    const db = firebase.firestore();
    return function worldWatcher() {
        return eventChannel((emitter) => {
            let ref = db.collection(collection);
            if (keyField) {
                if (!keyValue) {
                    ref = db.collection(collection).doc(keyField);
                    console.log('WATCHING:', collection, 'KEY', keyField);
                } else {
                    console.log('WATCHING:', collection, 'WHERE', keyField, op, keyValue);
                    ref = db.collection(collection).where(keyField, op, keyValue);
                }
            } else {
                ref = db.collection(collection);
            }
            if (orderBy) {
                if (orderBy.indexOf('-') === 0) {
                    ref = ref.orderBy(orderBy.replace('-', ''), 'desc');
                } else {
                    ref = ref.orderBy(orderBy);
                }
            }
            if (limit) {
                ref = ref.limit(limit);
            }


            const dataIn = (_data) => {
                const data = _data;
                data.added = true;
                emitter(data);
            };

            const dataModified = (_data) => {
                const data = _data;
                data.changed = true;
                emitter(data);
            };

            const dataRemoved = (_data) => {
                const data = _data;
                data.removed = true;
                emitter(data);
            };

            const unsubscribe = ref.onSnapshot((snapshot) => {
                let changes;
                if (snapshot.docChanges) {
                    changes = snapshot.docChanges();
                } else if (snapshot.data) {
                    changes = [snapshot];
                }

                changes.forEach((change) => {
                    let data;
                    if (change.doc) {
                        data = change.doc.data();
                        data._id = change.doc.id;
                    } else {
                        data = change.data();
                        if (!data) {
                            return;
                        }
                        data._id = change.id;
                    }

                    if (change.type === 'added') {
                        dataIn(data);
                    }
                    if (change.type === 'modified') {
                        dataModified(data);
                    }
                    if (change.type === 'removed') {
                        dataRemoved(data);
                    }
                    if (!change.type) {
                        dataIn(data);
                    }
                });
            });

            return () => {
                unsubscribe();
            };
        });
    };
}

export function createRef(collection, keyField, keyValue, op = '==', orderBy = null, limit = null, startAfter = null) {
    const db = firebase.firestore();
    let ref = db.collection(collection);
    if (!keyValue) {
        ref = db.collection(collection).doc(keyField);
        console.log('CREATING REF:', collection, 'KEY', keyField);
    } else {
        console.log('CREATING REF:', collection, 'WHERE', keyField, op, keyValue);
        ref = db.collection(collection).where(keyField, op, keyValue);
    }
    if (orderBy) {
        if (orderBy.indexOf('-') === 0) {
            ref = ref.orderBy(orderBy.replace('-', ''), 'desc');
        } else {
            ref = ref.orderBy(orderBy);
        }
    }
    if (limit) {
        console.log('  LIMIT:', limit);
        ref = ref.limit(limit);
    }
    if (startAfter) {
        console.log('  START AFTER:', startAfter);
        ref = ref.startAfter(startAfter);
    }
    return ref;
}

export function genCardRootStyle(color = '#FF0000') {
    return {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: '1px',
        borderRightWidth: '1px',
        borderTopColor: color,
        borderBottomColor: color,
        borderLeftColor: color,
        borderRightColor: color,
        boxShadow: '3px 3px 2px #333333',
    };
}

export function genBorderStyle(color = '#FF0000') {
    return {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: '1px',
        borderRightWidth: '1px',
        borderTopColor: color,
        borderBottomColor: color,
        borderLeftColor: color,
        borderRightColor: color,
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
    };
}
