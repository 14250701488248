// Watcher and event emitter for firestore collections.

import firebase from 'firebase/app';
import { eventChannel } from 'redux-saga';

export default function createAuthWatcher() {
    const auth = firebase.auth();
    return function watcher() {
        return eventChannel((emitter) => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                emitter({ user });
            });

            return () => {
                unsubscribe();
            };
        });
    };
}
