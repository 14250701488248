import { connect } from 'react-redux';
import firebase from 'firebase/app';
import ApprovedLeaders from '../components/leaders';
import { showLogin } from '../state/user-actions';
import { setUIState } from '../state/ui-actions';
import { addApproved, deleteApproved } from '../state/approved-actions';

const mapStateToProps = state => ({
    user: state.user ? state.user : null,
    approvedEmail: typeof (state.ui.approvedEmail) !== 'undefined' && state.ui.approvedEmail ? state.ui.approvedEmail : '',
    approvedAccounts: state.approvedAccounts,
});

const mapDispatchToProps = dispatch => ({
    onLogin: () => {
        dispatch(showLogin());
    },
    onLogout: () => {
        firebase.auth().signOut();
    },
    onEmailChanged: (email) => {
        dispatch(setUIState('approvedEmail', email));
    },
    onAddNew: () => {
        dispatch(addApproved());
    },
    onDelete: (account) => {
        dispatch(deleteApproved(account));
    },
});

const ApprovedLeadersController = connect(mapStateToProps, mapDispatchToProps)(ApprovedLeaders);

export default ApprovedLeadersController;
