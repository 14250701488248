import { SET_APPROVED, REMOVE_APPROVED } from './approved-actions';

export function approvedAccounts(state = [], action) {
    switch (action.type) {
    case (SET_APPROVED): {
        let found = false;
        let newState = state.map((approved) => {
            if (approved._id === action.approved._id) {
                found = true;
                return action.approved;
            }
            return approved;
        });
        if (!found) {
            newState = [...newState, ...[action.approved]];
        }
        return newState;
    }
    case (REMOVE_APPROVED): {
        return state.filter(approved => approved._id !== action.approved._id);
    }
    default:
        return state;
    }
}
