import { connect } from 'react-redux';
import firebase from 'firebase/app';
import Settings from '../components/settings';
import { showLogin } from '../state/user-actions';
import { addPeriod, deletePeriod, savePeriod, fetchPickups } from '../state/settings-actions';
import { setUIState } from '../state/ui-actions';

const mapStateToProps = state => ({
    user: state.user ? state.user : null,
    periods: state.periods,
    savingPeriod: state.ui.savingPeriod,
    pickups: state.pickups,
    downloadReady: typeof (state.ui.csvDownloadReady) !== 'undefined' ? state.ui.csvDownloadReady : false,
});

const mapDispatchToProps = dispatch => ({
    onLogin: () => {
        dispatch(showLogin());
    },
    onLogout: () => {
        firebase.auth().signOut();
    },
    onAddNewPeriod: () => {
        dispatch(addPeriod());
    },
    onDeletePeriod: (account) => {
        dispatch(deletePeriod(account));
    },
    onWeekDayChanged: (_period, wd) => {
        const period = Object.assign({}, _period);
        period.weekDayIndex = wd.value;
        period.weekDay = wd.label;

        dispatch(savePeriod(period));
    },
    onStartChanged: (_period, hr) => {
        const period = Object.assign({}, _period);
        period.start = hr.value;

        dispatch(savePeriod(period));
    },
    onEndChanged: (_period, hr) => {
        const period = Object.assign({}, _period);
        period.end = hr.value;

        dispatch(savePeriod(period));
    },
    onDownloadPickups: () => {
        dispatch(fetchPickups());
    },
    onDownloaded: () => {
        dispatch(setUIState('csvDownloadReady', false));
    },
});

const SettingsController = connect(mapStateToProps, mapDispatchToProps)(Settings);

export default SettingsController;
