import React from 'react';
import { Route, Switch } from 'react-router';
import { useStyletron } from 'baseui';
import LoginRegisterController from './controllers/login-register';
import UpdateAppController from './controllers/update-app';
import HomeController from './controllers/home';
import ApprovedLeadersController from './controllers/leaders';
import SettingsController from './controllers/settings';
import './App.css';

const App = () => {
    const [css] = useStyletron();
    return (
        <div
            className={css({
                height: '100vh',
            })}
        >
            <div
                className={css({
                    backgroundColor: '#f5f5f5',
                    minWidth: '100%',
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                })}
            >
                <div className={css({
                    '@media screen and (min-width: 880px)': {
                        position: 'relative',
                    },
                })}
                >

                    <Switch>
                        <Route exact path="/" component={HomeController} />
                        <Route exact path="/leaders" component={ApprovedLeadersController} />
                        <Route exact path="/settings" component={SettingsController} />
                    </Switch>

                    <LoginRegisterController />
                    <UpdateAppController />

                </div>
                <div className={css({
                    marginTop: '15px', padding: '10px', color: '#FFFFFF', backgroundColor: '#232f3e', height: '20px', textAlign: 'center', fontSize: '10px', '@media screen and (min-width: 564px)': { fontSize: '18px' },
                })}
                >
                    Copyright 2020 - Rochester Regional Health - Emerging Technologies
                </div>
            </div>
        </div>
    );
};

export default App;
