import { takeLatest, put, call, take, select } from 'redux-saga/effects';
import firebase from 'firebase/app';
import moment from 'moment';
import 'firebase/firestore';
import uuidv4 from 'uuid/v4';
import { SAGA_ADD_PERIOD, SAGA_WATCH_PERIODS, SAGA_DELETE_PERIOD, SAGA_SAVE_PERIOD, SAGA_FETCH_PICKUPS, setPeriod, removePeriod, setPickups } from './settings-actions';
import { createWatcher, fetchRef } from '../libs/utils';
import { setUIState } from './ui-actions';

function* workerAddPeriod() {
    // GET USER

    const userId = yield select(state => state.user.email);

    // FIND NEXT DAY

    const days = {
        0: { value: 0, label: 'Monday' },
        1: { value: 1, label: 'Tuesday' },
        2: { value: 2, label: 'Wednesday' },
        3: { value: 3, label: 'Thursday' },
        4: { value: 4, label: 'Friday' },
        5: { value: 5, label: 'Saturday' },
        6: { value: 6, label: 'Sunday' },
    };
    const periods = yield select(state => state.periods);
    let maxDay = 0;
    periods.forEach((p) => {
        if (p.weekDayIndex > maxDay) {
            maxDay = p.weekDayIndex;
        }
    });
    if (periods.length > 0 && maxDay < 6) {
        maxDay++;
    }
    const nextDay = days[maxDay];


    // STORE NEW PERIOD

    const periodId = uuidv4();
    const period = {
        weekDay: nextDay.label,
        start: '0800',
        end: '1700',
        weekDayIndex: nextDay.value,
        user: userId,
    };

    const db = firebase.firestore();
    const approvedRef = db.collection('periods');
    yield approvedRef.doc(periodId).set(period);
}

export function* addPeriod() {
    yield takeLatest(SAGA_ADD_PERIOD, workerAddPeriod);
}

function* workerSavePeriod(action) {
    // SAVE PERIOD

    yield put(setUIState('savingPeriod', action.period._id));
    const period = Object.assign({}, action.period);
    delete period._id;
    const db = firebase.firestore();
    const approvedRef = db.collection('periods');
    yield approvedRef.doc(action.period._id).set(period);
    yield put(setUIState('savingPeriod', null));
}

export function* savePeriod() {
    yield takeLatest(SAGA_SAVE_PERIOD, workerSavePeriod);
}

function* workerDeletePeriod(action) {
    // CONFIRM

    if (confirm(`Remove period?`) === false) {    // eslint-disable-line
        return;
    }

    // DELETE

    const db = firebase.firestore();
    const approvedRef = db.collection('periods');
    yield approvedRef.doc(action.period._id).delete();
}

export function* deletePeriod() {
    yield takeLatest(SAGA_DELETE_PERIOD, workerDeletePeriod);
}

let periodsChannel;
function* workerWatchPeriods() {
    // WATCH TOPICS

    const periodsWatcher = createWatcher('periods');

    // CLOSE EXISTING CHANNEL

    if (periodsChannel) {
        periodsChannel.close();
    }

    periodsChannel = yield call(periodsWatcher);

    while (true) {
        // RESPOND TO EMITTER

        const period = yield take(periodsChannel);

        if (!period.removed) {
            yield put(setPeriod(period));
        } else {
            yield put(removePeriod(period));
        }
    }
}

export function* watchPeriods() {
    yield takeLatest(SAGA_WATCH_PERIODS, workerWatchPeriods);
}

function* workerFetchPickups() {
    // FETCH ALL PICKUPS

    yield put(setPickups([]));
    yield put(setUIState('csvDownloadReady', false));
    const db = firebase.firestore();
    const pickupRefs = db.collection('pickups').orderBy('date');
    let pickups = yield fetchRef(pickupRefs);

    pickups = pickups.map(pu => (
        {
            account: pu.account,
            created: moment.unix(pu.created.seconds).format('MM-DD-YYYY HH:mm'),
            periodDay: pu.periodDetails.weekDay,
            periodStart: pu.periodDetails.start,
            periodEnd: pu.periodDetails.end,
            userEmail: pu.userEmail,
            userHandle: pu.userHandle,
        }));
    yield put(setPickups(pickups));
    yield put(setUIState('csvDownloadReady', true));
}

export function* fetchPickups() {
    yield takeLatest(SAGA_FETCH_PICKUPS, workerFetchPickups);
}
