import { SET_PERIOD, REMOVE_PERIOD, SET_PICKUPS } from './settings-actions';

export function periods(state = [], action) {
    switch (action.type) {
    case (SET_PERIOD): {
        let found = false;
        let newState = state.map((period) => {
            if (period._id === action.period._id) {
                found = true;
                return action.period;
            }
            return period;
        });
        if (!found) {
            newState = [...newState, ...[action.period]];
        }
        return newState;
    }
    case (REMOVE_PERIOD): {
        return state.filter(period => period._id !== action.period._id);
    }
    default:
        return state;
    }
}

export function pickups(state = [], action) {
    let newState;
    switch (action.type) {
    case (SET_PICKUPS):
        newState = null;
        if (action.pickups) {
            newState = Array.from(action.pickups);
        }
        return newState;
    default:
        return state;
    }
}
